// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-our-partners-en-js": () => import("./../../../src/pages/about-us/our-partners.en.js" /* webpackChunkName: "component---src-pages-about-us-our-partners-en-js" */),
  "component---src-pages-about-us-press-links-js": () => import("./../../../src/pages/about-us/press-links.js" /* webpackChunkName: "component---src-pages-about-us-press-links-js" */),
  "component---src-pages-ad-specs-en-js": () => import("./../../../src/pages/ad-specs.en.js" /* webpackChunkName: "component---src-pages-ad-specs-en-js" */),
  "component---src-pages-api-dynamic-codes-en-js": () => import("./../../../src/pages/api-dynamic-codes.en.js" /* webpackChunkName: "component---src-pages-api-dynamic-codes-en-js" */),
  "component---src-pages-api-en-js": () => import("./../../../src/pages/api.en.js" /* webpackChunkName: "component---src-pages-api-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-customer-faq-en-js": () => import("./../../../src/pages/customer-faq.en.js" /* webpackChunkName: "component---src-pages-customer-faq-en-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-gap-setup-guide-en-js": () => import("./../../../src/pages/gap-setup-guide.en.js" /* webpackChunkName: "component---src-pages-gap-setup-guide-en-js" */),
  "component---src-pages-gift-with-purchase-setup-guide-en-js": () => import("./../../../src/pages/gift-with-purchase-setup-guide.en.js" /* webpackChunkName: "component---src-pages-gift-with-purchase-setup-guide-en-js" */),
  "component---src-pages-gtm-documentation-en-js": () => import("./../../../src/pages/gtm-documentation.en.js" /* webpackChunkName: "component---src-pages-gtm-documentation-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-integration-en-js": () => import("./../../../src/pages/integration.en.js" /* webpackChunkName: "component---src-pages-integration-en-js" */),
  "component---src-pages-integration-guide-en-js": () => import("./../../../src/pages/integration-guide.en.js" /* webpackChunkName: "component---src-pages-integration-guide-en-js" */),
  "component---src-pages-learn-more-en-js": () => import("./../../../src/pages/learn-more.en.js" /* webpackChunkName: "component---src-pages-learn-more-en-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-request-demo-en-js": () => import("./../../../src/pages/request-demo.en.js" /* webpackChunkName: "component---src-pages-request-demo-en-js" */),
  "component---src-pages-rewards-setup-guide-en-js": () => import("./../../../src/pages/rewards-setup-guide.en.js" /* webpackChunkName: "component---src-pages-rewards-setup-guide-en-js" */),
  "component---src-pages-success-stories-food-case-en-js": () => import("./../../../src/pages/success-stories/food-case.en.js" /* webpackChunkName: "component---src-pages-success-stories-food-case-en-js" */),
  "component---src-pages-success-stories-index-en-js": () => import("./../../../src/pages/success-stories/index.en.js" /* webpackChunkName: "component---src-pages-success-stories-index-en-js" */),
  "component---src-pages-success-stories-jewellery-case-en-js": () => import("./../../../src/pages/success-stories/jewellery-case.en.js" /* webpackChunkName: "component---src-pages-success-stories-jewellery-case-en-js" */),
  "component---src-pages-success-stories-ninjakitchen-en-js": () => import("./../../../src/pages/success-stories/ninjakitchen.en.js" /* webpackChunkName: "component---src-pages-success-stories-ninjakitchen-en-js" */),
  "component---src-pages-success-stories-online-4-baby-perks-en-js": () => import("./../../../src/pages/success-stories/online4baby-perks.en.js" /* webpackChunkName: "component---src-pages-success-stories-online-4-baby-perks-en-js" */),
  "component---src-pages-success-stories-parcel-2-go-en-js": () => import("./../../../src/pages/success-stories/parcel2go.en.js" /* webpackChunkName: "component---src-pages-success-stories-parcel-2-go-en-js" */),
  "component---src-pages-success-stories-plusnet-perks-en-js": () => import("./../../../src/pages/success-stories/plusnet-perks.en.js" /* webpackChunkName: "component---src-pages-success-stories-plusnet-perks-en-js" */),
  "component---src-pages-success-stories-secret-sales-en-js": () => import("./../../../src/pages/success-stories/secret-sales.en.js" /* webpackChunkName: "component---src-pages-success-stories-secret-sales-en-js" */),
  "component---src-pages-success-stories-supplement-case-en-js": () => import("./../../../src/pages/success-stories/supplement-case.en.js" /* webpackChunkName: "component---src-pages-success-stories-supplement-case-en-js" */),
  "component---src-pages-success-stories-wild-natural-deodorant-case-en-js": () => import("./../../../src/pages/success-stories/wild-natural-deodorant-case.en.js" /* webpackChunkName: "component---src-pages-success-stories-wild-natural-deodorant-case-en-js" */),
  "component---src-pages-terms-of-service-en-js": () => import("./../../../src/pages/terms-of-service.en.js" /* webpackChunkName: "component---src-pages-terms-of-service-en-js" */),
  "component---src-pages-tyviso-gap-en-js": () => import("./../../../src/pages/tyviso-gap.en.js" /* webpackChunkName: "component---src-pages-tyviso-gap-en-js" */),
  "component---src-pages-tyviso-gwp-en-js": () => import("./../../../src/pages/tyviso-gwp.en.js" /* webpackChunkName: "component---src-pages-tyviso-gwp-en-js" */),
  "component---src-pages-tyviso-recommends-en-js": () => import("./../../../src/pages/tyviso-recommends.en.js" /* webpackChunkName: "component---src-pages-tyviso-recommends-en-js" */),
  "component---src-pages-tyviso-rewards-en-js": () => import("./../../../src/pages/tyviso-rewards.en.js" /* webpackChunkName: "component---src-pages-tyviso-rewards-en-js" */),
  "component---src-pages-your-goals-acquire-new-customers-en-js": () => import("./../../../src/pages/your-goals/acquire-new-customers.en.js" /* webpackChunkName: "component---src-pages-your-goals-acquire-new-customers-en-js" */),
  "component---src-pages-your-goals-raise-customer-lifetime-js": () => import("./../../../src/pages/your-goals/raise-customer-lifetime.js" /* webpackChunkName: "component---src-pages-your-goals-raise-customer-lifetime-js" */),
  "component---src-pages-your-goals-uplift-basket-completion-js": () => import("./../../../src/pages/your-goals/uplift-basket-completion.js" /* webpackChunkName: "component---src-pages-your-goals-uplift-basket-completion-js" */)
}

